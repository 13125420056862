
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ isAuth, children }) => {
    const navigate = useNavigate();

    function redirect() {
        navigate('/login');
    }

    return isAuth ? children : redirect();
};

export default PrivateRoute;
