/* Common */

function passOutputToParent(jsonData, activities) {
    // Send data to parent window
    // const data = {
    //     nodeKey: 6,
    //     configData: jsonData,
    //     configLines: activities
    // };   
    window.parent.postMessage(jsonData, '*');
}

function isJsonString(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

export { passOutputToParent, isJsonString };